<style>
.input-readonly>div>.vs-inputx {
	background-color: #e6e6e6;
}

.bg-dark {
	background-color: #e6e6e6 !important;
}

.d-flex {
	display: flex;
}

.justify-center {
	justify-content: center;
}

.justify-right {
	justify-content: right;
}

.align-center {
	align-items: center;
}
</style>
<template>
	<div class="vx-col w-5/6 ml-auto mr-auto">
		<span>
			<h4 class="mb-5">Create Invoice</h4>
		</span>

		<div class="vx-row mb-2">
			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Supplier</label>

				<multiselect class="selectExample" v-model="create.supplier" :options="option.supplier" :multiple="false"
					:allow-empty="false" :group-select="false" :max-height="160" :limit="4" :internal-search="true"
					placeholder=" Type to search" track-by="ID" label="Name"
					@select="selectedInvoice">
				</multiselect>
			</div>
			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Warehouse</label>

				<multiselect class="selectExample" v-model="create.warehouse" :options="option.warehouse" :multiple="false"
					:allow-empty="false" :group-select="false" :max-height="160" :limit="4" :internal-search="true"
					placeholder=" Type to search" track-by="ID" label="name"
					@select="selectedInvoice">
				</multiselect>
			</div>
			<div class="vx-col w-full mb-2">
				<label class="vs-input--label">Purchase Order</label>

				<multiselect class="selectExample" v-model="create.invoice" :options="option.invoice" :multiple="true"
					:allow-empty="true" :group-select="false" :max-height="160" :limit="4" :internal-search="true"
					placeholder=" Type to search" track-by="id" label="label" 
					@select="selectedInvoice">
				</multiselect>
			</div>
			<div v-if="!globalIsEmptyObject(create.invoice)" class="vx-col w-full">
				<vs-card v-for="(invoice, index) in create.invoice" :key="index">
					<div slot="header">
						<div class="vx-row" style="padding-left: 10px">
							<div style="width: 80%">
								<h6 style="left-margin: 1%">
									{{ invoice.code }}
								</h6>
							</div>
						</div>
					</div>
					<div class="vx-row">
						<div class="vx-col sm:w-2/3 w-full">
							Date : {{ globalDateFormat(invoice.date) }}<br />
							Due Date : {{ globalDateFormat(invoice.eta) }}<br />
							Posting Date : {{ globalDateFormat(invoice.posting_date) }}<br />
							Created Date : {{ globalDateFormat(invoice.created_at) }}<br />
							Sales Order Code : {{ invoice.so_code }}<br />
							Invoice Reference Code : {{ invoice.external_code }}<br />
							Note : {{ invoice.notes }}
						</div>
						<div class="vx-col sm:w-1/3 w-full">
							Subtotal : {{ priceFormat(invoice.subtotal) }}<br />
							Discount : {{ priceFormat(invoice.discount) }}<br />
							Subtotal Charge : {{ priceFormat(invoice.total_charge) }}<br />
							Other Cost : N/A<br />
							Tax : {{ priceFormat(invoice.tax) }}<br />
							Total : {{ priceFormat(invoice.total) }}<br />
							Remaining : N/A
						</div>
						<!-- {{ invoice }} -->
					</div>
				</vs-card>
			</div>

			<vs-divider style="width: 100%; margin-left: 2%">Invoice Data</vs-divider>
			<div class="vx-col sm:w-1/2 w-full">
				<vs-input name="invoiceNumber" v-model="create.invoiceNumber" class="w-full input-readonly" readonly
					label="Invoice Number"/>
				<!-- <vs-input name="invoiceType" v-model="create.invoiceType" class="w-full"
					 label="Invoice Type"/> -->
				<vs-input name="externalCode" v-model="create.externalCode" class="w-full"
					label="External Code"/>
				<vs-input name="barcode" v-model="create.barcode" class="w-full"
					label="Barcode"/>
			</div>

			<div class="vx-col sm:w-1/2 w-full">
				<label class="vs-input--label">Invoice Date</label>
				<datepicker
					class="w-full"
					v-validate="'required'"
					:inline="false"
					name="Invoice Date"
					v-model="create.invoiceDate"
					:danger="errors.has('invoiceDate')"
				></datepicker>
				<span v-show="errors.has('invoiceDate')" class="text-danger text-sm help is-danger">
					{{ errors.first("invoiceDate") }}
				</span>
				<label class="vs-input--label">Payment Term</label>
				<multiselect
					class="selectExample"
					v-model="create.paymentTerm"
					:options="option.paymentTerm"
					:multiple="false"
					:allow-empty="false"
					:group-select="false"
					:max-height="160"
					:limit="4"
					placeholder=" Type to search"
					track-by="id"
					label="name"
					:disabled="false"
				>
				<template slot="singleLabel" slot-scope="dt">
					<span class="option__desc">
					<span class="option__title">{{ dt.option.name }}</span>
					</span>
				</template>

				<template slot="option" slot-scope="dt">
					<div class="option__desc">
					<span class="option__title">{{ dt.option.name }}</span>
					</div>
				</template>
				</multiselect>
				<!-- display: block -->
				<span v-show="errors.has('paymentTerm')" class="text-danger text-sm help is-danger">
					{{ errors.first("paymentTerm") }}
				</span>
				<label class="vs-input--label">Invoice Due Date</label>
				<datepicker
					class="w-full"
					v-validate="'required'"
					:inline="false"
					name="Invoice Due Date"
					v-model="create.invoiceDueDate"
					:danger="errors.has('invoiceDueDate')"
				></datepicker>
				<span v-show="errors.has('invoiceDueDate')" class="text-danger text-sm help is-danger">
					{{ errors.first("invoiceDueDate") }}
				</span>
			</div>

			<div class="vx-col w-full mt-6">
				<div class="vx-row mb-2">
					<div class="vx-col w-1/3">
						<vs-input class="w-full input-readonly" label="Item Value" v-validate="'required'" name="DN Value" readonly
							v-model="create.itemValue" />
						<vs-input class="w-full input-readonly" label="Charges Value" v-validate="'required'" readonly
							name="DN Value" v-model="create.chargeValue" />
					</div>
					<div class="vx-col w-1/3">
						<vs-input class="w-full input-readonly" label="Tax Value" readonly name="VAT OUT"
							v-model="create.taxValue" />
						<vs-input class="w-full input-readonly" label="Discount Value" readonly name="VAT OUT"
							v-model="create.DiscountValue" />
					</div>
					<div class="vx-col w-1/3 d-flex justify-center align-center">
						<vs-input v-validate="'required'" class="w-full input-readonly" label="Total Invoice Value" readonly
							v-model="create.totalValue" />
					</div>
				</div>
			</div>

			<div class="vx-col w-full mt-12">
				<div class="vx-row mb-2">
					<div class="vx-col w-1/3">
					</div>
					<div class="vx-col w-1/3 d-flex justify-right align-center">
						<label class="vs-input--label"><b>Advance Invoice Value</b></label>
					</div>
					<div class="vx-col w-1/3 d-flex justify-center align-center">
						<vs-input v-validate="'required'" class="w-full input-readonly" name="Grand Total"
						@keypress="globalIsNumber($event)" @keyup="grand_total = formatPrice(grand_total.toString())" readonly
						v-model="grand_total" />
					</div>
				</div>
			</div>

			<vs-divider style="width: 100%;">Notes</vs-divider>
			<template v-for="note in fileNotes">
				<b v-bind:key="note">{{ note }}
					<br>
				</b>
			</template>
			<div class="vx-col w-full">
				<label class="vs-input--label">Note</label>
				<vs-textarea v-model="create.note" />
			</div>

			<vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
			<div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
				<div class="vx-col sm:w-1/3 w-full">
					<span>File</span>
				</div>
				<div class="vx-col sm:w-2/3 w-full">
					<div class="vx-col sm:w-4/5 w-full">
						<input id="fileInput" name="file" class="w-full inputx" type="file" ref="file" multiple="multiple"
							accept=".jpg, .jpeg, .png .pdf" />
					</div>
				</div>
			</div>
			<div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
				<div class="vx-col sm:w-1/3 w-full">
					<span></span>
				</div>
				<div class="vx-col sm:w-2/3 w-full">
					<div class="vx-col sm:w-4/5 w-full">
						<vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
					</div>
				</div>
			</div>
			<!-- display: block -->
			<vs-divider style="width: 100%; margin-left: 2%" v-if="fileAttachment.length > 0">
				List Attachment
			</vs-divider>
			<div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
				<table class="vs-table vs-table--tbody-table">
					<template v-for="(tr, i) in fileAttachment">
						<tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile">
							<td class="td vs-table--td">{{ tr.NameFile }}</td>

							<td class="td vs-table--td">
								<vx-tooltip text="Show" v-if="tr.PathFile != ''">
									<vs-button type="line" icon-pack="feather" icon="icon-eye"
										@click.stop="downloadFileAwsS3(tr.PathFile)" />
								</vx-tooltip>
							</td>

							<td class="td vs-table--td">
								<template>
									<vx-tooltip text="Delete">
										<vs-button type="line" icon-pack="feather" icon="icon-trash"
											@click.stop="() => { fileAttachment.splice(i, 1); }" />
									</vx-tooltip>
								</template>
							</td>
						</tr>
					</template>
				</table>
			</div>
		</div>

		<div class="vx-col w-full">
			<br />
			<!-- display: block -->
			<vs-button class="mb-2" v-on:click="handleSubmit">
				Create Payment
			</vs-button>
		</div>
	</div>
</template>

<script>
import qs from 'qs';
import Datepicker from "vuejs-datepicker";
import moment from "moment";
// import { Validator } from 'vee-validate';

export default {
	components: {
		Datepicker,
	},
  props: {
    selected: {
      type: Object,
    },
  },
	created() { },
	data() {
		return this.initialState();
	},
	methods: {
		selectedInvoice(arg) {
			let remaining = parseFloat(arg.Remaining).toFixed(2)
			this.maxAdjustment = remaining
		},
		initialState() {
			return {
				fileAttachment: [],
				search: "",
				maxAdjustment: 0,
				grand_total: 0,
				fileNotes: null,
				option: {
					invoice: [{
						"charge_type": "Capitalize",
						"code": "W3208-770323000002",
						"company_id": 2,
						"count_print": "0",
						"created_at": "2023-03-01 11:29:31",
						"created_by": 303,
						"currency_code": "IDR",
						"date": "2023-02-28",
						"deleted_at": null,
						"deleted_by": null,
						"discount": "0",
						"eta": "2023-02-28",
						"external_code": "3031048838",
						"from_warehouse_id": 49,
						"id": 14064,
						"is_buying_price": "0",
						"is_internal": "0",
						"is_partial": "1",
						"label": "3031048838 - W3208-770323000002",
						"notes": "0100012395362974",
						"notes_approval": null,
						"operating_unit_code": "O3208",
						"operating_unit_id": 4,
						"original_tax": "134343.88",
						"payment_method_name": "Bank Transfer",
						"payment_term_id": 20,
						"payment_term_name": "Net - 30 Day (TRF)",
						"posting_date": "2023-02-28",
						"prorate_method": "Quantity",
						"purchase_group_id": 1,
						"purchase_type_id": 1,
						"so_code": null,
						"sources": "Import",
						"status": "1",
						"status_invoice": 0,
						"status_reversal": 0,
						"subtotal": "1221308",
						"supplier_address": "Jl. BSD Boulevard Barat Green Office",
						"supplier_city": "KAB. TANGERANG",
						"supplier_code": "EH",
						"supplier_contact_name": "Wahyu Ahdnriyanto",
						"supplier_id": 1,
						"supplier_name": "PT. Unilever Indonesia Tbk (GT)",
						"supplier_phone": "622180827000",
						"tax": "134343",
						"total": "1489994.88",
						"total_charge": "0",
						"updated_at": "2023-03-08 09:57:54",
						"updated_by": 303,
						"virtual_code": "W3208-770323000002-3"
						},{
						"charge_type": "Capitalize",
						"code": "W3208-770323000001",
						"company_id": 2,
						"count_print": "0",
						"created_at": "2023-03-01 11:29:31",
						"created_by": 303,
						"currency_code": "IDR",
						"date": "2023-02-28",
						"deleted_at": null,
						"deleted_by": null,
						"discount": "0",
						"eta": "2023-02-28",
						"external_code": "3031048838",
						"from_warehouse_id": 49,
						"id": 14065,
						"is_buying_price": "0",
						"is_internal": "0",
						"is_partial": "1",
						"label": "3031048838 - W3208-770323000001",
						"notes": "0100012395362974",
						"notes_approval": null,
						"operating_unit_code": "O3208",
						"operating_unit_id": 4,
						"original_tax": "134343.88",
						"payment_method_name": "Bank Transfer",
						"payment_term_id": 20,
						"payment_term_name": "Net - 30 Day (TRF)",
						"posting_date": "2023-02-28",
						"prorate_method": "Quantity",
						"purchase_group_id": 1,
						"purchase_type_id": 1,
						"so_code": null,
						"sources": "Import",
						"status": "1",
						"status_invoice": 0,
						"status_reversal": 0,
						"subtotal": "1221308",
						"supplier_address": "Jl. BSD Boulevard Barat Green Office",
						"supplier_city": "KAB. TANGERANG",
						"supplier_code": "EH",
						"supplier_contact_name": "Wahyu Ahdnriyanto",
						"supplier_id": 1,
						"supplier_name": "PT. Unilever Indonesia Tbk (GT)",
						"supplier_phone": "622180827000",
						"tax": "134343",
						"total": "1489994.88",
						"total_charge": "0",
						"updated_at": "2023-03-08 09:57:54",
						"updated_by": 303,
						"virtual_code": "W3208-770323000002-3"
						}],
					supplier: [],
					warehouse: [],
					coa: [],
					costCenter: [],
					paymentTerm: [],
				},
				additionalCoa: [{
					list_id: null,
					additional_value: null,
					cost_center_id: null,
					cost_center_code: null,
					cost_center_name: null,
					coa_id: null,
					coa_code: null,
					coa_name: null,
					reason: null,
				}],
				inputs: [{
					additional_value: null,
					cost_center: null,
					cost_center_required: false,
					coa: null,
					reason: null,
				}],
				create: {
					invoice: [],
					supplier: {},
					warehouse: {},
					adjustmentValue: null,
					postingDate: null,
					coa: null,
					reason: "",
					costCenter: null,
					controlValue: null,
					paymentTerm: {},
					itemValue: 0,
					totalValue: 0,
					DiscountValue: 0,
					taxValue: 0,
					chargeValue: 0,
				},
				customNotif: {
					custom: {
						adjustmentValue: {
							required: "Adjustment value is empty",
							between: (field, max) => `Adjustment value field must be between 0 and ${max.toString().split(",")[1]}`
						},
						reason: {
							required: () => "Reason is empty",
						},
						costCenter: {
							required: "Coct center value is empty",
						},
						postingDate: {
							required: "Posting date value is empty",
						},
					},
				},
			};
		},
		mappingAttachment() {
			this.adjustment.Attachment.map(v => {
				this.fileAttachment.push({
					ListID: v.ListID,
					NameFile: v.NameFile,
					PathFile: v.PathFile,
				});
			})
		},
		handleAttachment() {
			if (this.$refs.file.files.length > 0) {
				this.file = this.$refs.file.files;
				// this.fileAttachment = [];
				for (let i = 0; i < this.$refs.file.files.length; i++) {
					console.log(this.file[i]);
					// this.uploadData(i);
					this.fileAttachment.push({
						NameFile: this.file[i].name,
						File: this.file[i],
					});
				}
				document.querySelector("#fileInput").value = "";
			} else {
				this.$vs.notify({
					title: "Error",
					text: "Please select file to import.",
					color: "danger",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-check",
				});
			}
		},
		formatPrice(angka, prefix = "") {
			return angka
				.toString()
				.replace(/,/g, "")
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
	getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.option.supplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouses", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.option.warehouse = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Warehouse option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseOrder() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-po", {
          params: {
            supplier_id: this.create.supplier.ID,
            warehouse_id: this.create.warehouse.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.option.invoice = resp.data.records;
            console.log(resp.data.records);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get PO option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.warehouse = null;
          }
          this.$vs.loading.close();
        });
    },
		paramUpload(array) {
			var form = new FormData();
			form.append("id", this.selected.ID);
			form.append("file", this.file[array]);
			return form;
		},
		uploadAttachmentAfter(i, id) {
			console.log("this line jalan");
			if (this.fileAttachment.length == 0) {
				this.$emit("closeDetail", true);
				this.$vs.loading.close();
				return;
			}
			if (this.fileAttachment.length < i) {
				this.$emit("closeDetail", true);
				this.$vs.loading.close();
				return;
			}
			var form = new FormData();
			form.append("id", id);
			form.append("file", this.fileAttachment[i].File);
			this.$http
				.post("/api/v1/advance-invoice/upload-file", form, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					this.uploadAttachmentAfter(++i, id);
					// this.$vs.loading.close();
				})
				.catch((error) => {
					this.uploadAttachmentAfter(++i, id);
				});
			// })
		},
		handleShow(file) {
			let objectURL;
			console.log(file);
			objectURL = URL.createObjectURL(file.File);

			// link.download = file.name; // this name is used when the user downloads the file
			var href = objectURL;
			window.open(href, "_blank").focus();
		},
		handleDeleteAttachment(id) {
			console.log(id);
			this.$vs.loading();
			var form = new FormData();
			form.append("list_id", id);
			form.append("id", this.selected.ID);
			this.$http
				.post("/api/v1/advance-invoice/delete-file", form, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					this.$vs.loading.close();
					if (resp.code == 200) {
						this.fileAttachment = this.fileAttachment.filter((v) => {
							return v.ListID != id;
						});
						this.$vs.notify({
							color: "success",
							title: "Success",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					} else {
						this.$vs.notify({
							color: "danger",
							title: "Error",
							text: resp.message,
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
					}
				})
				.catch((error) => {
					this.$vs.loading.close();
					console.log(error);
				});
		},
		getOptionCostCenter() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/master/cost-center", {
					params: {
						order: "name",
						sort: "asc",
					},
				})
				.then((resp) => {
					if (resp.code == 200) {
						this.option.costCenter = resp.data.records;
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Cost Center option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
		getOptionCoa(query) {
			if (query.length <= 2) {
				return;
			}
			this.$vs.loading();
			this.$http
				.get("/api/v1/master/chart-of-account", {
					params: {
						search: query,
						order: "code",
						sort: "asc",
					},
				})
				.then((resp) => {
					if (resp.code == 200) {
						// var _this = this
						this.option.coa = [];
						let coa = [];
						resp.data.records.map((v, i) => {
							coa.push(v.coa);
						});
						this.option.coa = coa;
					} else {
						this.$vs.notify({
							title: "Error",
							text: "Failed to get Coa option",
							color: "danger",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-check",
						});
					}
					this.$vs.loading.close();
				});
		},
		getGeneralForm() {
			// this.getOptionCoa();
			this.getOptionCostCenter();
		},
		getOptionPaymentTerm() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/master/payment-term", {
				params: {
					order: "name",
					sort: "asc",
				},
				})
				.then((resp) => {
				if (resp.code == 200) {
					this.option.paymentTerm = resp.data.records;
				} else {
					this.$vs.notify({
					title: "Error",
					text: "Failed to get Payment Term option",
					color: "danger",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-check",
					});
				}
				this.$vs.loading.close();
				});
			},
		handleSubmit() {
			this.$validator.validateAll().then((result) => {
				console.log(result);
				console.log("this.inputs[i]", this.inputs);
				if (result) {
					this.$vs.loading();
					console.log(this.create.paymentTerm, "on here");
					const params = {
						due_date: this.create.invoiceDueDate,
						payment_term_id: this.create.paymentTerm.id,
						payment_term_name: this.create.paymentTerm.name,
						date: this.create.invoiceDate,
						barcode: this.create.barcode,
						external_code: this.create.externalCode,
						type: 1,
						invoices: this.create.invoice,
						notes: this.create.note,
						supplier: this.create.supplier,
						warehouse: this.create.warehouse,
						territory_id: this.$userLogin.territory_id,
						territory_area_id: this.$userLogin.territory_area_id,
					};
					// console.log("params 321: ", params);
					this.$http
						.post("/api/v1/advance-invoice/store", params)
						.then((result) => {
							this.$vs.loading.close();
							if (result.code == 200) {
								// this.handleClose()
								this.$vs.notify({
									title: "Success",
									text: "",
									color: "success",
									position: "top-right",
									iconPack: "feather",
									icon: "icon-check",
								});
								this.uploadAttachmentAfter(0, result.data.invoice.ID);
							} else {
								this.handleClose();
								this.$vs.notify({
									title: "Error",
									text: result.message,
									color: "danger",
									position: "top-right",
									iconPack: "feather",
									icon: "icon-check",
								});
							}
						})
						.catch((e) => { });
				} else {
					this.$vs.notify({
						title: "Error",
						text: this.errors.all().join("\n"),
						color: "danger",
						position: "top-right",
						iconPack: "feather",
						icon: "icon-alert-circle",
					});
				}
			});
		},
		handleClose() {
			window.scrollTo(0, 0);
			this.$emit("closeDetail", true);
		},
		onChangeCoa(index) {
			if (this.inputs[index].coa.Code.slice(0, 1) == "7") {
				// console.log(this.option.costCenter);

				let costCenter = this.option.costCenter.filter(v => v.Code == "OTH")
				// console.log(costCenter, "OTH");

				console.log(costCenter, "costCenter[0]");
				// this.create.costCenter = costCenter[0]
				this.inputs[index].cost_center = costCenter[0]
				this.inputs[index].cost_center_required = true
			} else {
				this.inputs[index].cost_center_required = false
			}
		},
		adjustmentValue() {
			let adjustment = 0
			for (let i = 0; i < this.inputs.length; i++) {
				adjustment += this.inputs[i].additional_value == null ? 0 : parseFloat(this.inputs[i].additional_value.toString().replace(/,/g, ""))
				// console.log("adjustment", adjustment);
			}

			// console.log("adjustment2", adjustment);
			this.create.adjustmentValue = isNaN(adjustment) ? null : this.formatPrice(adjustment.toFixed(2));
		},
		removeField(index) {
			this.inputs.splice(index, 1)
		},
		addField() {
			this.inputs.push({
				additional_value: null,
				cost_center: null,
				coa: null,
				reason: null,
			})
			this.option.coa = []
			console.log(this.option.coa)
			this.additionalCoa.push({
				list_id: null,
				additional_value: null,
				cost_center_id: null,
				cost_center_code: null,
				cost_center_name: null,
				coa_id: null,
				coa_code: null,
				coa_name: null,
				reason: null,
			})
		},
	},
	mounted() {
		// this.$validator.localize("en", this.customNotif);
		this.getGeneralForm();
		this.getOptionSupplier();
		this.getOptionWarehouse();
		this.getOptionPaymentTerm();

    this.create.invoiceDueDate = this.selected
    this.create.paymentTerm.id = this.selected
    this.create.paymentTerm.name = this.selected
    this.create.invoiceDate = this.selected
    this.create.barcode = this.selected
    this.create.externalCode = this.selected
    this.create.invoice = this.selected
    this.create.note = this.selected
    this.create.supplier = this.selected
    this.create.warehouse = this.selected
	},
	computed: {},
	watch: {
		// "option.paymentTerm": function () {
		// 	if (
		// 		this.option.paymentTerm.length > 0 &&
		// 		this.create.paymentTerm != null
		// 	) {
		// 		let _this = this;
		// 		var result = this.option.paymentTerm.filter(function (o) {
		// 			return o.id == _this.create.paymentTerm.id;
		// 		});
		// 		// this.table.payment_term.due_invoice_period =
		// 		// result[0].due_invoice_period;
		// 	}
		// },
		"create.invoice": function () {
			this.create.itemValue = 0;
			this.create.totalValue = 0;
			this.create.DiscountValue = 0;
			this.create.taxValue = 0;
			this.create.chargeValue = 0;

			this.create.invoice.forEach(e => {
				this.create.itemValue = this.priceFormat(parseFloat(this.create.itemValue.toString().replace(/,/g, "")) + parseFloat(e.subtotal));
				this.create.totalValue = this.priceFormat(parseFloat(this.create.totalValue.toString().replace(/,/g, "")) + parseFloat(e.total));
				this.create.DiscountValue = this.priceFormat(parseFloat(this.create.DiscountValue.toString().replace(/,/g, "")) + parseFloat(e.discount));
				this.create.taxValue = this.priceFormat(parseFloat(this.create.taxValue.toString().replace(/,/g, "")) + parseFloat(e.tax));
				this.create.chargeValue = this.priceFormat(parseFloat(this.create.chargeValue.toString().replace(/,/g, "")) + parseFloat(e.total_charge));
			});

			this.grand_total = this.create.totalValue
		},
		"create.supplier": function () {
			// this.po = null;
			// this.warehouse = null
			// console.log(this.create.warehouse.ID, "ini apa");
			if (this.create.warehouse.ID != undefined || this.create.warehouse.ID != 0) {
				this.getOptionPurchaseOrder();
			}
		},
		"create.warehouse": function () {
			// console.log(this.create.supplier.ID, "ini apa juga");
			if (this.create.supplier.ID != undefined || this.create.supplier.ID != 0) {
				this.getOptionPurchaseOrder();
				// this.getOptionAccountBank(this.supplier.ID, 0);
			}
		},
	},
};
</script>
<style scoped>
.pagination {
	display: flex;

	margin: 0.25rem 0.25rem 0;
}

.pagination button {
	flex-grow: 1;
}

.pagination button:hover {
	cursor: pointer;
}

.border-danger {
	border: 1px solid rgba(var(--vs-danger), 10) !important;

	border-radius: 5px;
}
</style>
