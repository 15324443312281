<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Edit Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="create.supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          @search-change="getOptionSupplier"
          :disabled="false"
        >
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="create.territory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.code + ' ' + dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.code + ' ' + dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Method</label>
        <multiselect
          class="selectExample"
          v-model="create.method"
          :options="optionMethod"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{dt.option.BankName + ' ' + dt.option.AccountNumber}}
               <br> {{dt.option.AccountName}}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{dt.option.BankName + ' ' + dt.option.AccountNumber}}
               <br> {{dt.option.AccountName}}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      
      <div class="vx-col w-full">
        <label class="vs-input--label">Date Payment</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date Payment"
          v-model="create.date_payment"
          placeholder="Date Deposit"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
          <!-- disabled-dates="{from: new Date()}" -->
      </div>

      <div class="vx-col w-full" v-if="create.method != 'Transfer'"  >
        <label class="vs-input--label">{{this.create.method}} Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date"
          v-model="create.date_giro"
          :placeholder="'Date '+create.method"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
      </div>

      <div class="vx-col w-full" v-if="create.method != 'Transfer'">
         <label class="vs-input--label">Number Of {{this.create.method}}</label>
          <vs-input
            class="w-full"
            v-model="create.number_of"
          />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Posting Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Posting Date"
          v-model="create.date_posting"
          placeholder="Date Deposit"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
          
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.supplierBank"
          :options="optionBankSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{dt.option.BankName}} {{dt.option.AccountNumber}} <br>({{dt.option.AccountName}})
               </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{dt.option.BankName}} {{dt.option.AccountNumber}} <br>({{dt.option.AccountName}})
               </span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea v-model="create.note"/>
      </div>

      <div class="vx-row w-full ml-4" >
         <vs-input
          class="w-full"
          label="Ref Code"
          name="Ref Code"
          v-model="create.reference_code"
          
        />
          
      </div>
      <div class="vx-row w-full ml-4" >
         <vs-input
          class="w-full"
          label="Amount"
          name="Amount"
          v-model="create.amount"
          @keyup="create.amount = formatPrice(create.amount.toString())"
        />
          
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Non-Trade</label>
        <vs-checkbox :checked="create.isNonTrade">
						</vs-checkbox>
      </div>
      
       <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.PathFile)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(tr.ListID)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="vx-col w-full">
        <br>
        <vs-button class="mb-2" v-on:click="handleSubmit">Update Payment</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        lengthFile: 0,
        file:[],
        fileAttachment:[],
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionBankSupplier: [],
        optionPaymentProposal: [],
        optionTerritory:[],
        optionOperatingUnitBank:[],
        optionMethod:['Giro','Cheque','Transfer'],
        create:{
          supplier:null,
          operatingUnitBank: null,
          method: 'Giro',
          territory:null,
          reference_code:'',
          date_payment: '',
          date_giro:'',
          date_posting:'',
          amount:0,
          number_of:'',
          note:'',
          isNonTrade: false,
        }
      }
    },
    getGeneralForm() {
      this.$http.get('/api/v1/other-payment/for-dn/form-general/0').then((r) => {
          // this.optionTerritory = r.data.territory
          this.optionTerritory = r.data.territories
          this.optionTerritory.map(v => {
            if (this.selected.TerritoryID == v.ID){
              this.create.territory = v
            }

          })
          // this.optionBank = r.data.bank
          // this.optionOperatingUnitBank = r.data.operatingUnitBank
        }).catch((e) => {
          console.log(e)
        })
    },
    getOptionSupplierBank() {
      this.$http.get('/api/v1/pi/supplier-account/' + this.selected.PartnerID).then((r) => {
        this.optionBankSupplier = r.data.account
        this.optionBankSupplier.map(v => {
          if (v.BankID == this.selected.SupplierBankID && v.AccountNumber == this.selected.SupplierAccountNumber ) {
            this.create.supplierBank = v
          }
        })
        console.log(r)
      }).catch((e) => {
        console.log(e)
      })

    },
    formatPrice(angka, prefix = "") {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    handleSubmit() {
      if (this.create.operatingUnitBank == null) {
         this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      if (this.create.territory == null) {
         this.$vs.notify({
          title: "Error",
          text: "please select territory first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      if (this.create.amount == 0) {
         this.$vs.notify({
          title: "Error",
          text: "amount can't be empty",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return
      }
      this.$vs.loading()
      console.log(this.create.supplierBank)
      const params = {
        partner_code: this.create.supplier.Code,
        partner_name: this.create.supplier.Name,
        partner_id: this.create.supplier.ID,
        id: this.selected.ID,
        territory_id: this.create.territory.ID,
        payment_date: moment(this.create.date_payment == '' ?new Date(): this.create.date_payment ).format('YYYY-MM-DD'),
        giro_date: moment(this.create.date_giro == '' ?new Date(): this.create.date_giro ).format('YYYY-MM-DD'),
        posting_date: moment(this.create.date_posting == '' ?new Date(): this.create.date_posting ).format('YYYY-MM-DD'),
        bank_id: this.create.operatingUnitBank.BankID,
        bank_name: this.create.operatingUnitBank.BankName,
        account_number: this.create.operatingUnitBank.AccountNumber,
        account_name: this.create.operatingUnitBank.AccountName,
        account_branch: this.create.operatingUnitBank.BranchName,
        suplier_bank_id: this.create.supplierBank.BankID,
        suplier_bank_name: this.create.supplierBank.BankName,
        suplier_account_number: this.create.supplierBank.AccountNumber,
        suplier_account_name: this.create.supplierBank.AccountName,
        suplier_account_branch: this.create.supplierBank.BranchName,
        amount: parseFloat(this.create.amount.toString().replace(/,/g, '')),
         note: this.create.note,
        reference_code: this.create.reference_code,
        method: this.create.method,
      }
      console.log(params)
      this.$http.post('/api/v1/other-payment/for-dn',params)
        .then(result => {
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose()
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose()
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }).catch(e => {

        })
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail", true);
    },
    handleAttachment() {
      
      if (this.$refs.file.files.length > 0) {
        this.lengthFile = this.$refs.file.files.length  
        this.file = this.$refs.file.files;
        // for(let i =0; i < this.$refs.file.files.length; i++) {
          
          this.uploadData(0);
        // }
        
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    uploadData(array) {
      if (this.lengthFile <= array ) {
          // this.$emit("closeDetail", true);
          // this.$vs.loading.close();
          document.querySelector("#fileInput").value = "";
          return 
      }
      this.$vs.loading();
      this.$http
        .post("/api/v1/other-payment/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.fileAttachment = []
            resp.data.attachment.map(v => {
              this.fileAttachment.push({
                ListID: v.ListID,
                NameFile: v.NameFile,
                PathFile: v.PathFile,
              });
            })
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.uploadData(++array)
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
          this.uploadData(++array)
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    handleDeleteAttachment(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("list_id", id);
      form.append("id", this.selected.ID);
      this.$http
        .post("/api/v1/other-payment/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            console.log(this.fileAttachment)
            console.log(id)
            this.fileAttachment = this.fileAttachment.filter(v => {
              return v.ListID != id
            })
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getBankForm() {
      this.$http.get('/api/v1/other-payment/for-dn/form-general/' + this.selected.TerritoryID).then((r) => {
          this.optionOperatingUnitBank = r.data.operatingUnitBank
          this.optionOperatingUnitBank.map(v => {
            if (v.AccountNumber == this.selected.BankAccountNumber) {
              this.create.operatingUnitBank = v
            }
          })
        }).catch((e) => {
          
        })
    },
    
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            this.optionSupplier.map(v=>{
              if (v.ID == this.selected.PartnerID) {
                this.create.supplier = v
              }
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    console.log(this.selected)
    this.getGeneralForm();
    this.create.method = this.selected.PaymentMethodDesc
    this.create.reference_code = this.selected.ReferenceCode
    this.create.amount = this.formatPrice(this.selected.Amount)
    console.log(this.selected.DatePayment)
    this.create.date_payment = this.selected.DatePayment
    this.create.date_giro = this.selected.GiroDate
    this.create.number_of= this.selected.GiroNumber
    this.create.date_posting = this.selected.DatePosting
    this.create.note = this.selected.Note
    this.create.isNonTrade = this.selected.IsNonTrade
    this.fileAttachment = this.selected.Attachment
    this.getOptionSupplier(this.selected.PartnerName)
    this.getOptionSupplierBank()
  },
  computed: {},
  watch: {
    "create.supplier.ID"(){ 
      // console.log(this.create.supplier)
      
    },
    "create.territory.ID"() {
      this.getBankForm()
    },
    "selected.ID" (){
      console.log(this.selected)
      this.getOptionSupplier(this.selected.PartnerName)
      this.getOptionSupplierBank()
      this.create.method = this.selected.PaymentMethodDesc
      this.create.reference_code = this.selected.ReferenceCode
      this.create.amount = this.formatPrice(this.selected.Amount)
      this.create.date_payment = this.selected.DatePayment
      this.fileAttachment = this.selected.Attachment
      this.create.date_giro = this.selected.GiroDate
      this.create.number_of= this.selected.GiroNumber
      this.create.date_posting = this.selected.DatePosting
      this.create.note = this.selected.Note
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>